import { render, staticRenderFns } from "./AdminWashRequestAddStaffModal.vue?vue&type=template&id=20213faa&scoped=true"
import script from "./AdminWashRequestAddStaffModal.vue?vue&type=script&lang=js"
export * from "./AdminWashRequestAddStaffModal.vue?vue&type=script&lang=js"
import style0 from "./AdminWashRequestAddStaffModal.vue?vue&type=style&index=0&id=20213faa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20213faa",
  null
  
)

export default component.exports