export const regularHolidays = [
  0, 6
]

export const regularHolidays2 = [
  0
]

import Vue from 'vue'
import userApi from '@/api/user'
export default {
  methods: {
    getAvailableDate(targetDt, businessHolidays, isSaturdayAvailable) {
      if (isSaturdayAvailable === undefined) {
        isSaturdayAvailable = 0
      }
      // 一時的に全てのユーザーに対して土曜集配を許可する(ver.10 #3)
      isSaturdayAvailable = 1

      const year = targetDt.getFullYear()
      const month = targetDt.getMonth() + 1
      const hours = targetDt.getHours()
      const minutes = targetDt.getMinutes()
      let yearAdjust = this.isNextYear(year, month) ? 1 : 0
      let availableDt = new Date(
        year + yearAdjust, month - 1, targetDt.getDate())

      for (let i = 1; i <= 31; ++i) {
        // 定休日考慮
        if (isSaturdayAvailable == 1) {
          if(this.isRegularHolidays2_(availableDt)) {
            availableDt.setDate((availableDt.getDate() + 1))
          }
        } else {
          if(this.isRegularHolidays_(availableDt)) {
            availableDt.setDate((availableDt.getDate() + 1))
          }
        }
        // 休業日考慮
        if(this.isBusinessHolidays_(availableDt, businessHolidays)) {
          availableDt.setDate((availableDt.getDate() + 1))
        }
      }

      yearAdjust = this.isNextYear(year, month) ? 1 : 0
      availableDt = new Date(
        availableDt.getFullYear() + yearAdjust, availableDt.getMonth(), availableDt.getDate(),
        hours, minutes, 0)

      return availableDt
    },
    getDateList(year, month) {
      let days = []
      const day = new Date(year, month, 0).getDate()
      for (let i = 1; i <= day; ++i) {
        days.push(i)
      }
      return days
    },
    getWeekDaysList(year, month, businessHolidays, isSaturdayAvailable) {
      if (isSaturdayAvailable === undefined) {
        isSaturdayAvailable = 0
      }
      // 一時的に全てのユーザーに対して土曜集配を許可する(ver.10 #3)
      isSaturdayAvailable = 1

      let days = []
      const day = new Date(year, month, 0).getDate()
      for (let i = 1; i <= day; ++i) {
        let date = new Date(year, month - 1, i)
        if (isSaturdayAvailable == 1) {
          if (!this.isRegularHolidays2_(date) && !this.isBusinessHolidays_(date, businessHolidays)){
            days.push(i)
          }
        } else {
          if (!this.isRegularHolidays_(date) && !this.isBusinessHolidays_(date, businessHolidays)){
            days.push(i)
          }
        }
      }
      return days
    },
    async getBusinessHolidays() {
      let businessHolidays = []
      await userApi.getBusinessHolidays().then(({ data }) => {
        businessHolidays = data
          .map(e => this.convBusinessHolidays_(e))
          .filter(e => this.filterBusinessHolidays_(e))
      })
      return businessHolidays
    },
    convBusinessHolidays_(data) {
      const businessHoliday = new Date(data.date)
      return new Date(businessHoliday.getFullYear(), businessHoliday.getMonth(), businessHoliday.getDate())
    },
    filterBusinessHolidays_(data) {
      let nowDate = new Date()
      let firstDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1)
      let lastDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1)
      lastDate.setMonth(lastDate.getMonth() + 3)
      lastDate.setDate(0)

      // 直近３ヶ月の休業日取得
      if(firstDate.getTime() <= data.getTime() && data.getTime() <= lastDate.getTime()) { return true }

      return false
    },
    isRegularHolidays_(date) {
      return regularHolidays.indexOf(date.getDay()) >= 0
    },
    isRegularHolidays2_(date) {
      return regularHolidays2.indexOf(date.getDay()) >= 0
    },
    isBusinessHolidays_(date, businessHolidays) {
      return businessHolidays.some(val => val.getTime() === date.getTime())
    },
    getWorkingDay(beginDate, endDate, businessHolidays, isSaturdayAvailable) {
      if (isSaturdayAvailable === undefined) {
        isSaturdayAvailable = 0
      }
      // 一時的に全てのユーザーに対して土曜集配を許可する(ver.10 #3)
      isSaturdayAvailable = 1

      // 期間内での休日を除く営業日数を算出
      const beginTime = beginDate.getTime()
      const endTime = endDate.getTime()
      const diff = endTime - beginTime
      // 時間差をミリ秒単位を日単位に変換し（切り捨て）、1日分を加算
      const term = Math.floor(diff / (24 * 60 * 60 * 1000) + 1)

      // 期間内の定休日日数
      let dayOffs = 0
      // 開始日付から終了日付までの曜日が定休日かの判定を行う
      const beginDay = beginDate.getDay()
      for (let i = 0; i < term; i++) {
        // 曜日数値に日数を加算していき、7で割った余りの曜日数値が定休日の配列に含まれるか
        if (isSaturdayAvailable == 1) {
          if (regularHolidays2.indexOf((beginDay + i) % 7) != -1) {
            // 定休日の配列に含まれる場合、休日数に加算する
            dayOffs++
          }
        } else {
          if (regularHolidays.indexOf((beginDay + i) % 7) != -1) {
            // 定休日の配列に含まれる場合、休日数に加算する
            dayOffs++
          }
        }
      }

      // 休業日考慮
      let holidayTime = []
      for (let i = 0; i < businessHolidays.length; i++) {
        const dateObj = new Date(businessHolidays[i])
        if (regularHolidays.indexOf(dateObj.getDay()) == -1) {
          // 定休日の曜日に存在しない場合、祝日のタイムスタンプの配列に追加
          holidayTime.push(dateObj.getTime());
        }
      }

      let holidaysCnt = 0
      for (let i = 0; i < holidayTime.length; i++) {
        if (beginTime <= holidayTime[i] && holidayTime[i] <= endTime) {
          // 期間内の休業日の場合追加
          holidaysCnt++
        }
      }

      return term - dayOffs - holidaysCnt
    },
    isNextYear(year, month) {
      const currentYear = new Date().getFullYear()
      if (currentYear < year) return false
      const currentMonth = new Date().getMonth() + 1
      return month < currentMonth
    },
  }
}
